  #logo-border{
    stroke-dasharray: 300;
    stroke-dashoffset: 1000;
    animation: dash 7s linear infinite;
    -webkit-animation: dash 7s infinite linear;
  }
  
  @keyframes dash {
    to {
      stroke-dashoffset: 0;
    }
  }
  #loader-container.hidden{
      animation: fade-out 1s;
      animation-fill-mode: forwards;
  }
  @keyframes fade-out{
      100%{
        opacity: 0;
        visibility: hidden;
      }
  }
  /* ViewBox controlls what is inside the viewable area */
  .z-svg{
    width: 200px; /* this is the viewport = viewable area */
    height: 200px;
    /* border:1px solid #a46ef6e8 */
  }
  #diamond-loader{
    /* height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  #logo-loader{
      width: 80px;
      position: absolute;
      top:50%;
      left: 50%;
      transform: translate(-50%, -50%);
  }
  #logo-loader img{
      width: 100%;
      display: block;
  }