.mobile-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.mobile-row-marginless {
    display: flex;
    flex-wrap: wrap;
}

.two-third {
	width :66%;
}
.one-third {
	width: 33%;
}
.sixty-percent {
    width: 60%;
}
.forty-percent {
    width: 40%;
}
.half-width {
    width: 50%;
    padding-right: 15px;
    padding-left: 15px;
}
.full-width {
	width: 100%;
}

.hover-thing:hover {
	cursor:pointer;
}
.mobile-ml-2 {
    margin-left: 0.5rem !important;
}
.mobile-mt-2 {
    margin-top: 0.5rem !important;
}
.selected-color {
    color: #673ab6;
}
.micro-margin-mobile-side {
    margin-left: 6px;
    margin-right: 6px;
}
.nano-margin-mobile-side {
    margin-left: 3px;
    margin-right: 3px;
}
.micro-margin-mobile-tb {
    margin-top: 6px;
    margin-bottom: 6px;
}
.nano-margin-mobile-tb {
    margin-top: 3px;
    margin-bottom: 3px;
}
.small-margin-top {
    margin-top: 12px;
}

.micro-padding-mobile-side {
    padding-left: 6px;
    padding-right: 6px;
}
.nano-padding-mobile-side {
    padding-left: 3px;
    padding-right: 3px;
}
.micro-padding-mobile-tb {
    padding-top: 6px;
    padding-bottom: 6px;
}
.nano-padding-mobile-tb {
    padding-top: 3px;
    padding-bottom: 3px;
}