/* OVERRIDING MATERIAL-UI CLASSES */

.MuiCheckbox-colorSecondary.Mui-checked {
    color: #262a94 !important;
}

.MuiTableRow-root.Mui-selected, 
.MuiTableRow-root.Mui-selected:hover {
    background-color: #e4d4ff !important;

}

/* .makeStyles-highlight-40 {
    background-color: #e4d4ff !important;
    color: #262a94 !important;
} */