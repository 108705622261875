.active-list {
    background:#e4d4ff;
}

.grid-box-list {
    height: 410px;
    overflow: auto;
    width:100%;
    background-color: white;
}

.grid-box-detail {
    height: 446px;
    overflow: auto;
}

.item {
    width: 100%;
    display: inline-flex;
    height: 40px;
    overflow: auto;
    cursor: pointer;
    text-align: center;
    align-items: center;
}

/*.item:first-child {
    background-color: #673ab7;
}*/

.value-name {
    width: 20%;
    margin: 0px;
    /*word-wrap: break-word;*/
    font-size: 12px;
    font-family: 'Lato', sans-serif !important;
}

.values {
    margin: 0px;
    width: 12%;
    word-wrap: break-word;
    font-size: 12px;
    font-family: 'Lato', sans-serif !important;
}

.value-url {
    width: 35%; 
    margin: 0px;
    overflow-x: auto;
    font-size: 12px;
    font-family: 'Lato', sans-serif !important;
}

.setting-icons {
    text-align: right;
}

.description-paragraph {
    margin: 0px;
    font-size: 12px;
}

.description-inline {
    display: flex;
    justify-content: space-between;
}

.details-header {
    background: #C9AEF0;
    color: #262A94;
    padding: 10px;
    /*border-radius: 10px 20px 0px 0px;*/
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px
}

.profile-initial {
    margin: 0px;
    height: 30px;
    width: 30px;
    /*border-radius: 25px;*/
    border: 2px solid #696969;
    padding: 4px;
}

.padding {
    padding-left: 14px;
}

.status {
    height: 10px;
    width: 10px;
    /*border-radius: 25px;*/
    margin-left: 44%;
}

.green {
    background: green;
}

.red {
    background: red;
}

.orange {
    background: orange;
}

.black {
    background: black;
}

.filter-right {
    text-align: right;
    font-size: 10px;
}

.MuiPaper-rounded {
    border-radius: 0 !important
}

.MuiTypography-subtitle1,
.MuiTypography-body1,
.MuiInputBase-input {
    font-family: 'Lato', sans-serif !important;
}