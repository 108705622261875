.main-btn {
    border-radius: 0!important;
    font-size: 13px !important;
    cursor: pointer;
    padding: 0 16px !important;
    height: 32px !important;
    font-weight: bold !important; 
    font-family: 'Lato', sans-serif !important;
}

.main-btn:hover {
    opacity: 0.8 !important;
    /*background-image: linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.2))!important;*/
    background-image: linear-gradient(rgba(103,58,183, 0.3), rgba(103,58,183, 0.3)) !important;
}
