@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

/* main shareable container */
.dashboard.animated.slideInUpTiny.animation-duration-3{
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
  margin-top:5%;
}

.black-color {
  color: #000;
}

.shareable-left-container{
  box-shadow: -9px 10px 10px 0px rgba(0,0,0,0.15);
}
.mui-style { 
  margin-left: 45%;
  padding-top: 100px;
  padding-bottom: 100px;
}
.mid-grey {
  color: #777777;
}
.right-align{
  text-align: right;
}
div.shareable-header-left.jr-card.jr-full-card{
  margin-bottom: 0px;
  float: left;
  width: 50%;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-left: 0%;
  background-color: #262A94;
  font-weight: 300;
  font-size: 26px;
  color: #fff;
  /*border-top-left-radius: 24px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;*/
}

i.fa.fa-heart {
  color:#B68BF7 !important;
}

i.fa.fa-heart-o {
  color:#262A94 !important;
}

div.shareable-header-right.jr-card.jr-full-card {
  margin-bottom: 0px;
  float: left;
  width: 50%;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-left: 0%;
  background-color: #B68BF7;
  font-size: 26px;
  color: #fff;
  /*border-top-right-radius: 24px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;*/
}

div.shareable-header-right.jr-card.jr-full-card:hover,
div.shareable-header-left.jr-card.jr-full-card:hover{
  text-decoration: none !important;
  box-shadow: 10px 10px 7px 0px rgba(0,4,115,0.16);
}

p.shareable-tile{
  color:white;
  font-weight:300;
  text-align: center;
  margin: auto;
}
.shareable-filter-container{
  background-color: #F8F8F8;
   padding: 15px;
   padding-top: 5%;
   padding-bottom: 5%;
   border-bottom: 1px solid #ccc;
}
.shareable-search-filter{
  display:flex;
  justify-content: space-between;
}

.shareable-search-filter input[type=text] {
  width: 60%;
  background: transparent;
  border: none;
  border-bottom: 2px solid  #B68BF7;
  font-size: 1.2rem;
  font-family: 'Lato', sans-serif;
  text-align: center;
  letter-spacing:2px;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  color: #444444;
  opacity: 0.44;
}

.shareable-search{
  font-size: 1rem !important;
  position: relative;
  right: 20px;
  color:#444444;
  opacity: 0.60;
}

.shareable-filter{
  color: #444444;
  text-transform: uppercase;
  opacity: 0.44;
  font-size:0.8rem;
  cursor: pointer;
  padding-top:10px;
  letter-spacing: 1.4px;
}

.shareable-menu-container{
  height:45px;
  width: 100%;
  background-color: #ffffff;
  padding-right: 15px;
  padding-top: 20px;
  box-shadow: inset 0px -54px 37px -60px rgba(184,184,184,1);
}

.shareable-menu-item{
  color: #808080;
  font-size:0.8rem;
  margin-top: auto;
  text-transform: uppercase;
  text-align: center;
  margin-right: -15px;

}


.shareable-menu-item-name{
  color: #808080;
  font-size:0.8rem;
  margin-top: auto;
  text-transform: uppercase;
}

.shareable-ziplist-container{
  width: 100%;
  margin-top: 0px;
  max-height: 350px !important;
  overflow-y: auto;
  /*border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;*/
}

.shareable-Zipdetails-delete-1:hover{
  color: #262A94;

}
/* end of main shareable container */


/* listItem */
.listItem-expansion{
  min-height: 80px !important;
}

div.MuiButtonBase-root.MuiExpansionPanelSummary-root.listItem-expansion.Mui-expanded{
  min-height: 80px !important;
  box-shadow: -5px 13px 22px 1px rgba(38,42,148,0.13);
  border:2px solid #C3C7DE;
  /*border-radius: 5px;*/
}
.listItem-expansionDetails{
  width: 100%;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 0 !important;
}

/*list item links*/
/* .listLinkItems-date{
  display:flex;
  font-size:0.5em;
} */
/* end of listItem */

/* shareable zips details modal */
.shareable-Zipdetails-container{
  margin-top:9%;
  max-height: 500px;
  overflow-y: auto;
  padding: 5px;
  box-shadow: 10px 10px 16px -1px rgba(38,42,148,0.29);
}

.shareable-Zipdetails-header{
  background-color:#F8F8F8;
  position: sticky;
  top: 5px;
  bottom: 5px;
  z-index: 30;
}
.shareable-Zipdetails-main-header{
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
.shareable-Zipdetails-title-container{
  display:flex;
  width: 100%;
}

.shareable-Zipdetails-title{
  color: #262A94;
  font-weight: bold;
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 0;
  font-size:1.3rem;
}

.shareable-Zipdetails-editNameQuick{
  margin-left:3.3% !important;
  width: 35%;
  min-width: 100px;
}

.shareable-Zipdetails-stepsEditIcon{
  margin-top: 10px;
  margin-right: 10px;
  font-size: 1.3rem;
  color: #262A94;
  opacity: 70%;
  cursor:pointer;
}
.shareable-Zipdetails-stepsEditIcon-open{
  margin-top: 10px;
  margin-right: 10px;
  font-size: 1.3rem;
  color: #262A94;
  cursor:pointer;
}


.shareable-Zipdetails-title-edit{
  display: flex;
  margin-left: 1%;
  margin-top: 1%;
  color: #262A94;
  opacity: 70%;
  font-size:1.1rem !important;
}

.shareable-Zipdetails-title-done-container{
  display:flex;
  color: #2A9426;
  position: relative;
  top: 15px;
  left: 10px;

}

.shareable-Zipdetails-title-done{
  display: flex;
  margin-left: 1%;
  margin-top: 1%;
  margin-right:10%;
  color: #2A9426;
  opacity: 70%;
  font-size:1.4rem !important;
  cursor:pointer;
}

.shareable-Zipdetails-title-done:hover{
  color: #2A9426;
  opacity:40%;
}

.shareable-Zipdetails-title-cancel{
  display: flex;
  margin-left: 1%;
  margin-top: 1%;
  color: #94262A;
  opacity: 70%;
  font-size:1.4rem !important;
  cursor:pointer;
}

.shareable-Zipdetails-title-cancel:hover{
  color: #94262A;
  opacity:40%;
}

.shareable-Zipdetails-editNameQuick input{
  color: #262A94;
/*  opacity:60%;*/
}

.shareable-Zipdetails-header-info{
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
  padding-top: 20px;
  color: #444444;
  opacity: 0.44;
  padding-left: 20px;
}

.shareable-Zipdetails-header-info h4{
  letter-spacing: 1.1px;
  font-size:0.8rem;
}

.shareable-Zipdetails-delete{
  color: #B68BF7;
  cursor:pointer
}

.shareable-Zipdetails-delete:hover{
  color: #262A94;
}

.shareable-Zipdetails-info p{
  color: #777777;
  font-weight: bold;
  margin-left: 20px;
  text-transform: uppercase;
  letter-spacing: 1.4px;
  font-size:0.65rem;
}

.shareable-Zipdetails-2{
  width:100%;
  margin:0 auto;
  height:1.5px;
}

.shareable-Zipdetails-allSteps{
  background-color:#fff;
  width: 100%;
}

.shareable-Zipdetails-allSteps a, .shareable-Zipdetails-allSteps a:active{
  color: #262A94;
}

label {
  padding-left: 20px;
  cursor:pointer;
}
.shareable-Zipdetails-btns{
  padding: 10px;
  z-index: 30;
}

.generateZipBtn{
  background: rgb(111,60,232);
  background: linear-gradient(90deg, rgba(111,60,232,1) 0%, rgba(139,90,237,1) 27%, rgba(149,112,233,1) 49%, rgba(186,156,231,1) 100%);
  color:white;
  float: left;
  width: 220px;
  height: 36px;
  z-index: 20;
  /*margin-top: -12px;*/
  /*border-radius: 15px;*/
  cursor:pointer;
}

.generateZipBtn h5{
  color:#fff;
  margin: 10px 0;
  text-align: center;
}
/* end of shareable zips details modal */

/* shareableModal  */
.shareablelink-container{
  border-bottom:0;
  margin: 0 20px;
  padding: 10px 40px;
}

.row{
  margin:0;
}
#customized-dialog-title h6{
  color:#262A94;;
  letter-spacing:1.2px;
}
.send-link-btn{
  background: rgb(111,60,232);
  background: linear-gradient(90deg, rgba(111,60,232,1) 0%, rgba(139,90,237,1) 27%, rgba(149,112,233,1) 49%, rgba(186,156,231,1) 100%);
  border-radius: 30px;
}

.send-link-btn span{
  color:white;
}

.sharaebleLink-title{
  color:#B68BF7;
  margin-bottom:5px;
  font-size: 1em;
  margin-top:10%;
}
.shareableLink-copylink{
  width: 100%;
  border: 1px solid #cccccc;
  background-color: #ffffff;
  color: #673ab6;
  height: 30px;
  display: flex;
}
.shareableLink-copylink-innner {
  width: 90%;
  overflow: auto;
  display: block;
  padding-top: 2px;
  padding-bottom: 2px;
}
.shareableLink-copylink-new{
  width: 100%;
  border: 1px solid #cccccc;
  background-color: #ffffff;
  color: #673ab6;
  display: flex;
}

.shareableLink-copylink h4{
  color: #444444;
  margin-left: 20px;
  margin-bottom: 10px;
  font-size: 12px;
  height: 10px;
}

.shareableLink-copylink .sharaebleLink-copy-btn{
  color:#B68BF7;
  font-size:1rem !important;
  text-align: right;
  padding-right: 5px;
  cursor: pointer;
}

.shareableLink-copylink .sharaebleLink-copy-btn:hover{
  color:#262A94;
}

.shareableLink-link-name-container{
     display:flex;
     width: 100%;
     padding-bottom: 10%;
}
.shareableLink-link-name{
  float: left ;
  width: 100%;
  margin-left:0%;
  height: 30px;
  margin-bottom: 10px;

}
.shareableLink-link-name label{
  color: #B68BF7;
  font-size: 1em;
}

.sharaebleLink-invite-header{
  width:100%;
  color: #B68BF7;
  font-size: 1em;
  margin-top:20px;
}
.sharaebleLink-invite-container{
  width:100%;
  top: 40px;
  margin-bottom:20% !important;
}
.sharaebleLink-invite-container li{
  color: #444444;
  opacity: 0.44;
  font-size: 0.8rem !important;
}

.sharaebleLink-invite-container input{
  font-size:0.8rem;
}

.sharaebleLink-btn-container{
  margin-bottom: 10px;
}

.sharaebleLink-teammates .css-6q0nyr-Svg {
  color: #B68BF7;
}

.sharaebleLink-teammates {
  padding: 0;
}

.sharaebleLink-teammates .css-9gakcf-option {
  background-color: #EFE7FF !important;
  color: #B68BF7 !important;
}

.css-1n7v3ny-option{
  background-color: #EFE7FF !important;
}

.css-4ljt47-MenuList{
  padding-top:0 !important;
  padding-bottom:0 !important;
}

.css-xb97g8:hover{
  background-color: #EFE7FF !important
}

.shareable-date-picker-container{
  float:left;
  width: 100%;
  height: 30px;
  margin-bottom: 40px;
}

.shareable-date-picker-container .MuiFormControl-root.MuiTextField-root{
  width:100%;
}

.shareable-date-picker  {
  float: left;
  width: 20%;
  margin-Left:0%;
}

.shareable-date-picker h4{
  color: #B68BF7;
  font-size: 1em !important;
  margin-top:10px !important;
}


.shareable-date-picker-modal{
  float: left;
  width: 50%;
  margin-left:0%;
}

.shareable-date-picker-modal label{
  display: none;
}

.shareable-date-picker-modal input{
  text-align: center;
  font-size: 1em;
  color: #575757;
}

.shareable-date-picker-modal button{
  color: #B68BF7;
  font-size: 1em;
}

button.shareableLink-link-generate-btn{
  text-transform: capitalize;
  border: 1px solid #B68BF7;
  font-size: 0.7rem;
  display: flex;
  margin: auto;
}
/* end of shareableModal  */

/* eachPage */
.eachPage-allSteps-Switch-container{
  display: flex;
  justify-content: flex-end;
  position: relative;
  left: 70px;
}
/* end of eachPage */

/* each Element */
.shareableEachPagelink-container{
  box-shadow: none !important;
}

.shareableEachPagelink-details{
  margin-left:20px;
}

.shareableEachPage-each-link h3, .shareableEachPage-each-link h4{
  display:flex;
  font-size: 1.1vw !important;
  color: #6769B4;
}

.shareableEachPage-links{
  display: flex;
  justify-content: space-between;
  padding-right: 0px;

}

.shareableEachPage-each-link h3 > span{
  color: #444444;
  opacity: 0.44;
  padding-left: 20px;
  padding-right: 5px;
}
.shareableEachPage-accordianContainer{
  width: 100%;
  justify-content: space-between;
  background-color: transparent;
  margin-top: 50px;
  box-shadow: none;
  overflow: hidden;
}

i.fas.fa-bolt{
  color:#B68BF7;
  margin-left:4px;
}

p.eachElement-triggerError{
  font-size: 0.7rem;
  width: 130px;
  position: relative;
  right: 60px;
  color: #262A94;
}

.wobble{
  animation: wobble 0.5s;
}

/* Trigger animation for switch on each step */
@keyframes wobble {
  0% { transform: translateX(0%); }
  15% { transform: translateX(-25%) rotate(-5deg); }
  30% { transform: translateX(20%) rotate(3deg); }
  45% { transform: translateX(-15%) rotate(-3deg); }
  60% { transform: translateX(10%) rotate(2deg); }
  75% { transform: translateX(-5%) rotate(-1deg); }
  100% { transform: translateX(0%); }
}
/* end of each Element */

/* animation for fade in */
.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* media queries for tablet  */
@media only screen and (max-width: 1060px) {
  .shareable-menu-container{
    padding-right: 0;
  }
  .shareable-left-container{
    width: 130% !important;
  }
  .shareable-Zipdetails-container{
    max-width: 90%;
    margin-left: 20%;
    margin-top: 14%;

  }
  .shareable-Zipdetails-info p{
    font-size: 0.50rem;
  }

  .shareable-menu-item{
    font-size: 0.7rem;
    padding-right: 10px;
  }

  .shareableEachPage-links{
    display:flex;
    flex-direction: column;
  }

  .shareable-Zipdetails-delete-1{
    display:flex;
  }

  .shareableEachPage-each-link{
    float:none;
    width:unset !important;
    display:flex;
    justify-content: unset !important;
  }

  .shareableEachPage-each-link h3{
    font-size: 1.1rem;
    margin-bottom: 20px;

  }
  .shareable-Zipdetails-delete {
    font-size: 1.1rem !important;
  }

  .shareablelink-main-container{
    width: 60% !important;
  }
  .sharaebleLink-copy-btn{
    margin-left: 3px;
  }
  .ShareableModal-dialogPaper-756{
    width:60%;
  }

  .shareable-search-filter input[type=text]{
    width:80%;
  }
}