/* Globals
   ========================================================================== */
@import "variables";
@import "mixin";
@import "place-holder";
@import "sizes";
@import "color";
@import "custom-animation";

.MuiOutlinedInput-root {
	border-radius: 0 !important;
}

.jr-card {
	border-radius: 0 !important;
}

.MuiChip-root {
	height: auto !important;
}